<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="名称" prop="card_name" :rules="rules.required">
					<el-input v-model="form.card_name"></el-input>
				</el-form-item>
				<el-form-item label="卡片背景" prop="card_img" :rules="rules.required">
					<el-radio v-model="form.card_img" :label="bg" v-for="(bg,index) in cardBgOptions" :key="index">
						<el-image style="width:100px;height: 50px;" :src="bg" fit="cover"></el-image>
					</el-radio>
				</el-form-item>
				<el-form-item label="价格" prop="price" :rules="rules.required">
					<el-input-number v-model="form.price" :precision="2" :step="0.1" :min="0" :max="99999"></el-input-number>
					<span class="margin-left-sm text-grey">元</span>
				</el-form-item>
				<el-form-item label="销售提成" prop="sales_commission" :rules="rules.required">
					<el-input-number v-model="form.sales_commission" :precision="2" :step="0.1" :min="0" :max="99999"></el-input-number>
					<span class="margin-left-sm text-grey">元</span>
				</el-form-item>
				<el-form-item label="会员卡简介" prop="desc">
					<!-- <el-input v-model="form.desc" type="textarea" :autosize="{ minRows: 4}"></el-input> -->
					<quill-editor ref="text" v-model="form.desc" class="myQuillEditor" :options="editorOption" />
				</el-form-item>
				<el-form-item label="购卡须知" prop="notice">
					<!-- <el-input v-model="form.notice"></el-input> -->
					<quill-editor ref="text" v-model="form.notice" class="myQuillEditor" :options="editorOption" />
				</el-form-item>
				<el-form-item label="推荐" prop="recommend">
					<el-switch v-model="form.recommend" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="标签" prop="tags">
					<el-checkbox-group v-model="form.tags">
						<el-checkbox :label="tag.tag_name" v-for="tag in tags" :key="tag.id">{{tag.tag_name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="有效期" prop="expired_date" :rules="rules.required">
					<el-input-number v-model="form.expired_date" :step="1" :min="0" :max="99999"></el-input-number>
					<span class="margin-left-sm text-grey">天（“0”表示永久）</span>
				</el-form-item>
				<el-form-item label="类型" prop="card_type">
					<el-radio v-model="form.card_type" :label="1">品牌服务卡</el-radio>
					<el-radio v-model="form.card_type" :label="2">自定义服务卡</el-radio>
				</el-form-item>
				<el-form-item label="服务项目" prop="card_item" :rules="rules.notEmptyItem">
					<div>
						<el-button icon="el-icon-plus" @click="openItems(1)">添加品牌服务</el-button>
						<el-button icon="el-icon-plus" @click="openItems(2)" v-if="form.card_type == 2">添加自营服务</el-button>
					</div>
					<div class="items auto-height margin-top">
						<div class="item not-select" v-for="item in form.card_item" :key="item.id">
							<el-image :src="item.cover" fit="cover"></el-image>
							<div class="cont">
								<div class="tit line-2">
									<span v-if="item.type==1" class="text-blue">[品牌]</span>
									<span v-if="item.type==2" class="text-green">[自营]</span>
									{{item.serve_name}}
								</div>
								<div class="price-box">
									<div class="price">￥{{item.price}}</div>
									<div class="count">
										<i class="el-icon-remove" @click.stop="countChange(-1,item)"></i>
										<el-input v-model.number="item.count" size="mini" @click.native.stop=""></el-input>
										<i class="el-icon-circle-plus" @click.stop="countChange(1,item)"></i>
									</div>
								</div>
							</div>
							<i class="el-icon-delete text-grey" @click.stop="delItem(item)"></i>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">保存</el-button>
					<el-button @click="$router.back()">取消</el-button>
				</el-form-item>
			</el-form>
			<!-- 选择服务项目 -->
			<el-dialog title="选择服务项目" :visible.sync="itemDialog.dialogVisible" width="800px" :append-to-body="true"
			:close-on-click-modal="false">
				<div class="items" v-loading="itemDialog.loading">
					<div class="item not-select" :class="{cur:item.cur}" v-for="item in itemDialog.items" @click="item.cur = !item.cur" :key="item.id">
						<el-image :src="item.cover" fit="cover"></el-image>
						<div class="cont">
							<div class="tit line-2">{{item.serve_name}}</div>
							<div class="price-box">
								<div class="price" v-if="item.price">￥{{item.price}}</div>
							</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="itemDialog.dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="addItem()">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	import {
		quillEditor
	} from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				editorOption: {
					modules: {
						toolbar: [
							['bold', 'italic', 'underline', 'strike', 'clean'],
						]
					}
				},
				rules: {
					...rules,
					notEmptyItem: {
						type: 'array',
						required: true,
						message: '请添加服务项目',
						trigger: 'change'
					}
				},
				tags: [],
				title: this.$route.params.id ? "编辑服务卡" : "新增服务卡",
				// 弹框
				itemDialog: {
					loading: false,
					dialogVisible: false,
					type: 1, // 1 品牌服务，2 自营服务
					items: [],
				},
				cardBgOptions: [
					'http://qiniu.memexia.com/temp/IMLUa1qqi6OHuikqMdT8DVfFeD508vrcV84rW8qC.jpeg',
					'http://qiniu.memexia.com/temp/9iBYLd2DjCIbclfT2pG9JCJvP0J4uoYGrbCcPiUb.jpeg'
				], // 背景图片选项
				form: {
					id: this.$route.params.id,
					card_name: '', //	否	string	服务卡名称
					card_img: '', //	是	string	服务卡背景图片
					price: '', //	是	number	价格
					sales_commission: '', // 销售提成
					tags: [], //	是	array	标签
					desc: '', //	是	string	服务描述
					notice: '', //	是	string	提示
					recommend: 0, //	否	number	推荐 0：不推荐 1：推荐
					card_type: 1, //	是	number	卡片类型 (1:品牌服务卡 2：自定义服务卡)
					count: 0, //	是	number	数量
					expired_date: 0, //	是	number	有效天数 （0 ：是永久 ）
					type: 0, //	是	number	服务类型 (1上门 2 到店 0 都支持)
					card_item: [ // 是 array 详情
						// serve_product_id: '', 	// 是 number 服务产品ID
						// serve_name: '', 			// 是 string 服务名称
						// type: 1, 				// 是 number 类型：（ 1: 品牌服务 2： 自营服务）
						// price: 0, 				// 是 number 价格
						// count: 0, 				// 是 number 数量
					]
				}
			};
		},
		computed: {
			isEdit() {
				return Boolean(this.form.id)
			}
		},
		mounted() {
			// 查询
			if (this.isEdit) {
				this.$api.card.getServeById({
					id: this.form.id
				}).then(res => {
					this.form = res.data
				})
			}
			// 获取标签列表
			this.$api.card.getTags().then(res => {
				this.tags = res.data;
			})
		},
		methods: {
			// 添加到服务项目
			addItem() {
				let selectedItems = this.itemDialog.items.filter(item => {
					return item.cur;
				}).map(item => {
					return {
						serve_product_id: item.serve_product_id, // 是 number 服务产品ID
						serve_name: item.serve_name, // 是 string 服务名称
						cover: item.cover,
						type: item.type, // 是 number 类型：（ 1: 品牌服务 2： 自营服务）
						price: item.price, // 是 number 价格
						count: 10 // 是 number 数量
					}
				})
				this.form.card_item.push(...selectedItems)
				this.itemDialog.dialogVisible = false;
			},
			// 修改次数
			countChange(num, item) {
				item.count += num
			},
			delItem(item) {
				let i = this.form.card_item.findIndex(p => p == item)
				if (~i) {
					this.form.card_item.splice(i, 1);
				}
			},
			// 打开选择服务弹框
			openItems(type) {
				this.itemDialog.loading = true;
				if (type == 1) {
					// 查询品牌项目
					this.$api.project.getBrandProjects({
						page: 1,
						page_size: 999
					}).then(res => {
						this.itemDialog.items = res.data.data.map(item => {
							return {
								serve_product_id: item.id,
								serve_name: item.serve_name,
								price: item.price,
								cover: item.cover,
								type: 1,
								cur: false
							}
						});
						this.itemDialog.loading = false;
					});
				} else {
					// 查询自营项目
					this.$api.project.getProjects({
						page: 1,
						page_size: 999
					}).then(res => {
						this.itemDialog.items = res.data.data.map(item => {
							return {
								serve_product_id: item.id,
								serve_name: item.server_name,
								price: item.current_price,
								cover: item.cover,
								type: 2,
								cur: false
							}
						});
						this.itemDialog.loading = false;
					})
				}
				this.itemDialog.dialogVisible = true
			},
			onSubmit() {
				if (this.form.card_type == 1) {
					let item = this.form.card_item.find(item => item.type == 2);
					if (item) {
						this.$message.error('品牌服务卡中不能包含自营服务')
						return false;
					}
				}
				this.$refs.form.validate(valid => {
					// 表单校验
					if (valid) {
						const loading = this.$loading();
						// 设置总次数
						let count = 0;
						this.form.card_item.forEach(item => {
							count += item.count;
						})
						this.form.count = count;
						this.$api.card.saveServe(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}

	.items {
		display: flex;
		flex-wrap: wrap;
		max-height: 500px;
		overflow: auto;

		&.auto-height {
			max-height: auto;

			.item {
				width: 400px;

				.cont {
					.tit {
						height: 55px;
					}
				}
			}

		}

		.item {
			width: 300px;
			margin: 0 20px 20px 0;
			height: 90px;
			border: 2px solid #ccc;
			border-radius: 10px;
			padding: 10px;
			display: flex;
			cursor: pointer;

			&.cur {
				border: 2px solid var(--green);
			}

			.cont {
				flex: 1;
				margin-left: 10px;
				position: relative;

				.tit {
					height: 70px;
					line-height: 20px;
					font-size: 16px;
				}

				.price-box {
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;


					.price {
						font-size: 20px;
						line-height: 20px;
						color: var(--orange);
					}

					.count {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 24px;
						color: var(--blue);

						i {
							// font-size: 30px;
							cursor: pointer;
						}

						.el-input {
							width: 43px;
							margin: 0 5px;
							font-size: 20px;
						}

						.el-input>>>input {
							padding: 0;
							text-align: center;
						}

					}
				}

			}

			.el-image {
				width: 90px;
				height: 90px;
				border-radius: 5px;
			}
		}
	}
</style>
